import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/about";
import Jobs from "../pages/jobs";
import Contact from "../pages/contact";


function AppRouter(){

    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="jobs" element={<Jobs/>}/>
                    <Route path="contact" element={<Contact/>}/>
                </Routes>
            </BrowserRouter>
        </>
    )

}

export default AppRouter;