import { useState, useEffect } from "react";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ContactForm from "../components/contactForm";

function Contact() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);
    return (
        <>
            <div className={`app ${fadeIn ? 'fade-in' : ''}`}>
                <Navbar scrollValue={200} />
                <ContactForm sx={{ paddingTop: '120px' }} />
                <Footer />
            </div>
        </>
    )
}

export default Contact;