import Button from "./button";
import { InputBox, InputSelectBox, TextBox } from "./inputs";

function JobForm() {

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-7 col-10 contact-form">
                        <p className="font-55">Think you have what it takes?
                            <br></br>Send us your resume</p>
                    </div>
                </div>
                <div className="input-form">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4"><InputBox type={"text"} label={"First Name"} /></div>
                        <div className="col-md-4"><InputBox type={"text"} label={"Last Name"} /></div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4"><InputBox type={"email"} label={"Email"} /></div>
                        <div className="col-md-4"><InputBox type={"text"} label={"Phone"} /></div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4"><InputSelectBox label={"Position you are applying to"} options={['Performance Specialist', 'Web Designer', 'Customer Success Manager', 'PPC Manager', 'Full Stack Developer']} /></div>
                        <div className="col-md-4"><InputBox type={"date"} label={"Available start date"} /></div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8"><InputBox type={"text"} label={"Link to your Resume"} /></div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-2 submit-btn">
                            <Button label={'Submit'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobForm;