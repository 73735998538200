import { useState, useEffect } from "react";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import AboutImage from '../assets/images/about-us.webp'
import ContactForm from "../components/contactForm";

function About() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);
    return (
        <>
            <div className={`app ${fadeIn ? 'fade-in' : ''}`}>
                <Navbar scrollValue={200} />
                <div className="page-main-content container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-6 box-round bg-blue">
                            <img width={'90%'} src={AboutImage} alt="" />
                        </div>
                        <div className="col-lg-4">

                        </div>
                        <div className="col-lg-4">
                            <div className="font-35 m-heading">Our Story</div>
                            <div className="font-18">At hAI Cloud Solutions, we are a team of experienced professionals who share a passion for innovation and problem-solving. We came together with the common goal of helping businesses accelerate innovation. Our journey began with a shared vision of creating a company that provides exceptional service and delivers measurable results. Today, we are proud to have helped numerous clients achieve their goals and establish long-term partnerships.</div>
                        </div>
                    </div>
                </div>
                <ContactForm />
                <Footer />
            </div>

        </>
    )
}

export default About;