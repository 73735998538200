import { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

import HeroVideo from '../assets/videos/hero-video.mp4'
import midContentimage from '../assets/images/mid-content.png'
import content1 from '../assets/images/content1.webp'
import content2 from '../assets/images/content2.png'
import content3 from '../assets/images/content3.webp'
import Button from "../components/button";
import Slides from "../components/slider";
import { useNavigate } from "react-router-dom";

function Home() {
    const navigate = useNavigate()
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);
    return (
        <>
            <div className={`app ${fadeIn ? 'fade-in' : ''}`}>
                <Navbar scrollValue={200} />

                <div className="video-hero">
                    <video src={HeroVideo} autoPlay muted loop />
                    <div className="content-overlay container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-10">
                                <p className="font-30">Innovate with hAI Cloud Solutions</p>
                                <p className="font-35 m-heading">We bring over 100+ years of collective experience from Google, Microsoft, Cisco, and Palo Alto networks. Let us partner with you in accelerating your digital transformation.</p>
                                <p className="font-18 mt-heading">We offer a variety of services including Training, Staffing, and Cloud solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-mid-content">
                    <div className="container">
                        <p className="font-55">hAI Cloud Solutions</p>
                        <p className="font-20">At hAI Cloud Solutions, we are known to solve the most complex scenarios.</p>
                        <img src={midContentimage} />
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-10">
                                <p className="font-18 padding-para"> Our team of IT experts will work with you to understand your business needs and create customized solutions that are tailored to your specific requirements. We provide expert guidance and support throughout the entire process, ensuring that you achieve your long-term goals.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-main-content container">
                    <div className="page-content-heading row justify-content-center">
                        <div className="col-md-8 col-10">
                            <p className="font-35">Why Choose hAI Cloud Solutions</p>
                        </div>
                        <div className="col-md-8 col-10">
                            <p className="font-55">Partner with us and take your business to the next level.</p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-6 box-round bg-blue">
                            <img src={content1} alt="" />
                        </div>
                        <div className="col-lg-4">

                        </div>
                        <div className="col-lg-4">
                            <div className="font-45">Landing zone for GCP and Azure</div>
                            <div className="font-25 m-heading">With our expert guidance and support, you can get up and running in no time.</div>
                            <div className="font-18">Our team of IT experts has years of experience in helping businesses of all sizes and industries. We provide customized solutions that are tailored to your specific requirements.</div>
                            <Button label={'Book a Consultation'} onclick={()=> navigate('/contact')}/>
                        </div>
                    </div>

                    <div className="row row-left align-items-center">
                        <div className="col-lg-4">
                            <div className="font-45">Cloud Cost Optimization</div>
                            <div className="font-25 m-heading">At hAI Cloud Solutions, we understand the importance of smart budgeting. That's why we offer a range of solutions that are designed to help you save money while achieving your business goals.</div>
                            <Button label={'Book a Consultation'} onclick={()=> navigate('/contact')}/>
                        </div>
                        <div className="col-lg-4">

                        </div>
                        <div className="col-lg-4 col-6 box-round-left bg-pink">
                            <img src={content2} alt="" />
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-4 col-6 box-round bg-green">
                            <img src={content3} alt="" />
                        </div>
                        <div className="col-lg-4">

                        </div>
                        <div className="col-lg-4">
                            <div className="font-45">On-site Experts and Training</div>
                            <div className="font-25 m-heading">Our team will work with you to create a customized skills development program. We offer a range of formats designed for your specific needs.</div>
                            <Button label={'Book a Consultation'} onclick={()=> navigate('/contact')}/>
                        </div>
                    </div>
                </div>
                <div className="container slider-wrap">
                    <div className="row justify-content-around align-items-center">
                        <div className="col-md-5 slider-title">
                            <p className="font-35">Our Clients</p>
                            <p className="font-55">Why Our Clients Choose hAI Cloud Solutions</p>
                        </div>
                        <div className="col-md-5 slide-box">
                            <Slides />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Home;