import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Navbar(props) {
    const { isHomeActive, isAboutActive, isJobsActive, isContactActive, scrollValue } = props;
    const [scrolled, setScrolled] = useState(false);

    const navigate = useNavigate();
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY >= scrollValue) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      };
      handleScroll();
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const navbarClass = scrolled ? "navbar scrolled" : "navbar";
    return (
        <>
            <nav className={navbarClass}>
                <div className="navbar-container container">
                    <input type="checkbox" name="" id="" />
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
                    <ul className="menu-items">
                        <li onClick={()=> {navigate('/')}}>Home</li>
                        <li onClick={()=> {navigate('/about')}}>About</li>
                        <li onClick={()=> {navigate('/jobs')}}>Jobs</li>
                        <li onClick={()=> {navigate('/contact')}}>Contact</li>
                    </ul>
                    <p className="logo" onClick={()=> {navigate('/')}}>hAI</p>
                </div>
            </nav>
        </>
    )
}

export default Navbar;