import { useState } from "react";
import Button from "./button";
import { InputBox, TextBox } from "./inputs";

function ContactForm(props) {
    const {sx} =props;

    const [formData, setFormData] =useState({});

    const submitHandler = (event) => {
        event.preventDefault();
        console.log(formData)
    }

    return (
        <>
            <div className="container" style={sx}>
                <div className="row justify-content-center">
                    <div className="col-md-7 col-10 contact-form">
                        <p className="font-70">Contact Us</p>
                        <p className="font-35">Get in touch with us for inquiries, questions, or business proposals. We are always happy to hear from you!</p>
                    </div>
                </div>
                <div className="input-form">
                    <form action="" onSubmit={submitHandler}>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4"><InputBox type={"text"} label={"First Name"} required={true}  onchange={(e) => setFormData({ ...formData, FirstName: e.target.value })}/></div>
                        <div className="col-md-4"><InputBox type={"text"} label={"Last Name"} required={true}  onchange={(e) => setFormData({ ...formData, LastName: e.target.value })}/></div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4"><InputBox type={"email"} label={"Email"} required={true}  onchange={(e) => setFormData({ ...formData, Email: e.target.value })}/></div>
                        <div className="col-md-4"><InputBox type={"text"} label={"Phone"} required={true}  onchange={(e) => setFormData({ ...formData, Phone: e.target.value })}/></div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8"><TextBox rows={4} label={"Leave us a message..."} required={true}  onchange={(e) => setFormData({ ...formData, Message: e.target.value })}/></div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-2 submit-btn">
                            <Button label={'Submit'} />
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ContactForm;