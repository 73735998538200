import 'boxicons/css/boxicons.min.css';
import FooterVideo from '../assets/videos/footer-video.mp4'

function Footer(props) {

    return (
        <>
            <div className="footer-wrap">
                <video src={FooterVideo} autoPlay loop muted />

                <div className="footer-overlay">
                    <div className="container footer">
                        <div className="row">
                            <div className="col-md-3 foot-box">
                                <p className="font-45">hAI</p>
                                <a href="mailto:info@highclouds.com"><p className="font-18 m-link link">Contact</p></a>
                            </div>
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-3 foot-box">
                                <p className="font-18">We're looking for talented, passionate folks to join our team.</p>
                                <a href="mailto:info@highclouds.com"><p className="font-18 m-link link">Jobs at hAI</p></a>
                            </div>
                        </div>
                    </div>
                    <div className="container foot-links-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6 footer-copyright font-18">
                                &copy; 2024. hAI Clouds
                            </div>
                            <div className="col-sm-6 footer-links font-30">
                                <i className='bx bxl-facebook'></i>
                                <i className='bx bxl-instagram-alt' ></i>
                                <i className='bx bxl-twitter' ></i>
                                <i className='bx bxl-linkedin' ></i>
                                <i className='bx bxl-youtube' ></i>
                                <i className='bx bxl-tiktok' ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer;