import { useState, useEffect } from "react";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import JobsImage from '../assets/images/jobs-main.webp'
import JobForm from "../components/jobForm";

function Jobs() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);
    return (
        <>
            <div className={`app ${fadeIn ? 'fade-in' : ''}`}>
                <Navbar scrollValue={200} />
                <div className="page-main-content container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-10 jobs">
                            <p className="font-70">Work at hAI Cloud Solutions</p>
                            <p className="font-30">There are always opportunities for talented people to join our epic team</p>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-11 job-main-img">
                            <img width={'100%'} src={JobsImage} alt="" />
                        </div>
                    </div>
                </div>
                <JobForm />
                <Footer />
            </div>

        </>
    )
}

export default Jobs;