import User1 from '../assets/images/user1.webp';
import User2 from '../assets/images/user2.webp';
import { useState } from 'react';

function Slides(props) {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleDotClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <div className="custom-carousel">
            <div className="carousel-inner">
                {[0, 1, 2].map((index) => (
                    <div
                        key={index}
                        className={`carousel-item ${activeIndex === index ? 'active' : ''}`}
                    >
                        <div className="slide-image">
                            {index === 0 && (
                                <>
                                    <p className="font-25 fade-in">"Expert Guidance and Support"</p>
                                    <p className="font-17 slide-margin fade-in">"hAI Cloud Solutions provided expert guidance and support throughout the entire process. Their team of IT experts helped us achieve our long-term goals and stay ahead of the competition."</p>
                                    <div className="user-img d-flex justify-content-center fade-in">
                                        <p className="font-14">S Khan, CEO NetCoro</p>
                                    </div>
                                </>
                            )}
                            {index === 1 && (
                                <>
                                    <p className="font-25 fade-in">"Customized Solutions that Work for Us"</p>
                                    <p className="font-17 slide-margin fade-in">"hAI Cloud Solutions provided customized solutions that were tailored to our specific requirements. Their team of IT experts worked closely with us to ensure that we achieved our business goals."</p>
                                    <div className="user-img d-flex justify-content-center align-items-center fade-in">
                                        <img src={User1} alt="" />
                                        <p className="font-14">Jane Smith CTO of XYZ Company</p>
                                    </div>
                                </>
                            )}
                            {index === 2 && (
                                <>
                                    <p className="font-25 fade-in">"Trusted Partner for Our IT Needs"</p>
                                    <p className="font-17 slide-margin fade-in">"hAI Cloud Solutions is our go-to partner for all our IT needs. Their team of experts has helped us stay ahead of the curve and achieve our business goals."</p>
                                    <div className="user-img d-flex justify-content-center align-items-center fade-in">
                                        <img src={User2} alt="" />
                                        <p className="font-14">Bob Johnson CFO of LMN Company</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="custom-dots">
                {[0, 1, 2].map((index) => (
                    <span
                        key={index}
                        className={`dot ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
}

export default Slides;
