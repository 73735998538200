

function Button(props){
    const {label, onclick, type} = props;

    return(
        <>
            <button onClick={onclick} type={type}>
                {label}
            </button>
        </>
    )
}

export default Button;