import './assets/css/component.css'
import './assets/css/pages.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AppRouter from './config/routes';

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
